import React, {useState} from "react";
import {callApi} from "../../services/apiService";
import responseHandler from "../../helpers/responseHandler";
import {subscribeField, submitField} from "./newsletter.module.scss"
const Newsletter = () => {
    const [subscribeForm, setSubscribeForm] = useState([]);
    function handleChange(e) {
        let inputName = e.target.name;
        let value = e.target.value;
        setSubscribeForm({
            ...subscribeForm,
            [inputName]: value,
        });
    }

    const subscribe = (e) => {
        e.preventDefault();
        callApi("/newsletter/subscribe", null, "post", subscribeForm)
            .then(response => {
                responseHandler("success", response.data)
                setSubscribeForm([]);
            })
            .catch( (error) => {
                responseHandler("error", error)
            });
    }
    return(
        <>
            <p>Newsletter</p>
            <form onSubmit={subscribe}>
                <input
                    className={subscribeField}
                    type="email"
                    name="email"
                    onInput={handleChange}
                    onChange={handleChange}
                    placeholder="Email"
                    value={subscribeForm?.email ?? ""}
                />
                <input
                    className={submitField}
                    type="submit"
                    name="join"
                    value="Join"
                />
            </form>
        </>
    )
}
export default Newsletter;