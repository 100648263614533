import {useEffect, useState} from "react";
import {
    gsap,
    Power0,
    Power1,
    Power2,
    Power3,
} from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";
import clsx from "clsx";
import {randomFloat, randomInt} from "../../helpers/randomise"
import { blood } from "./loading.module.scss";

const LoadingScreen = ({ isLoading, reset }) => {
    gsap.registerPlugin(ScrollTrigger);
    const [isHidden, setIsHidden] = useState(!isLoading); // change to false if you want to see it on load
    const [isCompleted, setIsCompleted] = useState(false);
    const [isResized, setIsResized] = useState(false);
    const power = [Power0, Power1, Power2, Power3];
    const strokeWidth = 15;

    let svgContainer, svgGroup, clearSVG, boxHeight, boxWidth;
    let tl = gsap.timeline({
        paused: true,
        // onUpdate: () => {
        //     console.log(this.progress());
        // }
    });

    function removeLines() {
        if (!isCompleted) return;
        while (svgGroup.childElementCount > 0) {
            svgGroup.removeChild(svgGroup.firstChild);
        }
        addLines();
    }

    function addLines() {
        const fragment = document.createDocumentFragment();

        boxHeight = svgContainer.getBoundingClientRect().height;
        boxWidth = svgContainer.getBoundingClientRect().width;
        svgContainer.setAttribute("viewbox", `0 0 ${boxWidth} ${boxHeight}`);

        for (let i = Math.ceil(boxWidth / strokeWidth); i > 0; i--) {
            const posX = (i - 1) * strokeWidth;
            const line = document.createElementNS("http://www.w3.org/2000/svg", "line");
            const ease = randomInt(0,2);

            line.setAttribute("x1", posX);
            line.setAttribute("x2", posX);
            line.setAttribute("y1", "0");
            line.setAttribute("y2", "100%");
            line.setAttribute("style", `stroke-width: ${strokeWidth + 2}; stroke-dasharray: ${boxHeight}; stroke-dashoffset: ${boxHeight};`);

            fragment.appendChild(line);

            tl.fromTo(
                line,
                randomInt(1, 2),
                { strokeDashoffset: boxHeight },
                {
                    strokeDashoffset: 0,
                    delay: randomFloat(0, .5 , 2),
                    ease: power[randomInt(0, 3)][`ease${ease ? (ease === 1 ? "In" : "Out") : "InOut"}`]
                },
                0);
        }

        svgGroup.appendChild(fragment);
    }

    function resizeHandler(){
        if (isCompleted) {
            clearTimeout(clearSVG);
            clearSVG = setTimeout(removeLines, 300);
        }
        else setIsResized(true);
    }

    useEffect(() => {
        window.addEventListener("resize", resizeHandler);
        return () => window.removeEventListener("resize", resizeHandler);
    });

    useEffect(() => {
        svgContainer = document.getElementById("svgContainer");
        svgGroup = svgContainer.getElementById("gTag");
        boxHeight = svgContainer.getBoundingClientRect().height;
        boxWidth = svgContainer.getBoundingClientRect().width;
        // if (!isHidden) return;
        // setIsHidden(true);
        tl.play();
        svgContainer.setAttribute("style", `height: calc(100vh + ${strokeWidth * 3}px);`);
        addLines();
        tl.eventCallback("onComplete", () => {
            setIsCompleted(true);
            tl.restart().pause();
            if (isResized) {
                setIsResized(false);
                removeLines();
            }
            setIsHidden(true);
            reset();
        });
    }, [])

    return(
        <svg
            className={clsx(blood, isHidden && "hidden")}
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="xMinYMin slice"
            id="svgContainer"
        >
            <filter id="liquify">
                <feGaussianBlur in="SourceGraphic"
                                stdDeviation="20"
                                result="blur"/>
                <feColorMatrix in="blur"
                               type="matrix"
                               // values="0.45 0   0   0   0 0   0.10  0   0   0 0   0   0.10  0   0 0   0   0   1   0"
                               values=".15 0 0 0 0,  0 0 0 0 0,  0 0 0 0 0,  0 0 0 100 -20"
                />
            </filter>
            <g id="gTag" filter="url(#liquify)" />
        </svg>
    )
}

export default LoadingScreen;
