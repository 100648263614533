import React, {useContext, useState} from "react";
import {useHistory} from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt} from "@fortawesome/free-solid-svg-icons/faExternalLinkAlt";
import { Transition } from 'react-transition-group';
import Socials from "../../components/socials/Socials";
import {ApiContext} from "../../components/contexts/ApiContext";
import LoadingScreen from "../../components/loading-screen/LoadingScreen";
import {latest, action, navigation, topRight, cta} from "./home.module.scss";
import {useScreenSize} from "../../helpers/media-query";

const LandingPage = () => {
    const history = useHistory();
    let {isLarge} = useScreenSize();
    const [showAnimation, setShowAnimation] = useState(false);
    const {featured_video, didVisitLanding} = useContext(ApiContext);

    const handleClick = () => {
        didVisitLanding();
        history.push("/music")
    }

    return(
        <>
            {isLarge && <Transition
                in={showAnimation}
                onExit={handleClick}
                timeout={200}
            >
                <>
                    {showAnimation &&
                    <LoadingScreen
                        isLoading={showAnimation}
                        reset={() => setShowAnimation(false)}
                    />
                    }
                </>
            </Transition>
            }
            <section className={latest}>
                <h2>Lurkin</h2>
                <a
                    className={action}
                    href={featured_video?.link ?? "https://www.youtube.com/channel/UCv_IYHV2vYcVG1LpZONFhBw/featured"}
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    Out now
                    <FontAwesomeIcon className={topRight} icon={faExternalLinkAlt} />
                </a>
                <p className={cta}>Stream on</p>
                <Socials />
                <input
                    className={navigation}
                    type="button"
                    onClick={() => isLarge ? setShowAnimation(true) : handleClick()}
                    value="Enter site"
                />
            </section>
        </>
    )
}
export default LandingPage;