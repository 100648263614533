import React, {useState} from "react";
import {callApi} from "../../services/apiService";
import responseHandler from "../../helpers/responseHandler";
import {contactDetails, name} from "./contact.module.scss";

function Contact() {
    const [contactForm, setContactForm] = useState([]);

    const increaseTextArea = (e) => {
        e.target.style.height = "";
        e.target.style.height = e.target.scrollHeight + "px";
    }

    function handleChange(e) {
        let inputName = e.target.name;
        let value = e.target.value;
        setContactForm({
            ...contactForm,
            [inputName]: value,
        });
    }

    const sendEnquiry = (e) => {
        e.preventDefault();
        callApi("/contact", null, "post", contactForm)
            .then(response => {
                responseHandler("success", response.data)
                setContactForm([]);
            })
            .catch( (error) => {
                responseHandler("error", error)
            })
    }

    return (
        <>
            <section className={contactDetails}>
                <form onSubmit={sendEnquiry}>
                    <article className={name}>
                        <input
                            name="first_name"
                            type="text"
                            placeholder="First Name"
                            required
                            value={contactForm?.first_name ?? ""}
                            onChange={handleChange}
                        />
                        <input
                            name="last_name"
                            type="text"
                            placeholder="Last Name"
                            required
                            value={contactForm?.last_name ?? ""}
                            onChange={handleChange}
                        />
                    </article>
                    <input
                        name="email"
                        type="email"
                        placeholder="Email"
                        required
                        onChange={handleChange}
                        value={contactForm?.email ?? ""}
                    />
                    <textarea
                        name="message"
                        placeholder="Message"
                        onInput={increaseTextArea}
                        required
                        onChange={handleChange}
                        value={contactForm?.message ?? ""}
                    />

                    <input value="Send" type="submit" />
                </form>
            </section>
        </>
    );
}

export default Contact;
