import React, { useLayoutEffect, useContext } from "react";
import { Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Home from '../../pages/home/Home';
import Contact from '../../pages/contact/Contact';
import Privacy from '../../pages/Privacy';
import Sitemap from '../../pages/Sitemap';
import Tour from '../../pages/tour/Tour';
import Shop from '../../pages/shop/Shop';
import LandingPage from "../../pages/home/LandingPage";
import {ApiContext} from "../../components/contexts/ApiContext";
import LoadingScreen from "../loading-screen/LoadingScreen";

function RouteControl() {
    const location = useLocation();
    const {didVisitLanding} = useContext(ApiContext);
    useLayoutEffect(() => {
        window.scrollTo(0, 0);
    }, [location.pathname]);

    // const LandingWithAnimation = () => {
    //     return (
    //         <TransitionGroup>
    //             <LandingPage />
    //             <CSSTransition
    //                 in={true}
    //                 onEnter={() => console.log("enter")}
    //                 onExited={() => console.log("exit")}
    //                 timeout={200}
    //                 className="test">
    //                 <LoadingScreen isLoading={true}/>
    //             </CSSTransition>
    //         </TransitionGroup>
    //     )
    // }

    return (
        <Switch>
            <Route exact path='/' component={
                () => didVisitLanding()
                    ? <Redirect to='/music' />
                    : <Redirect to='/promo' />
            }/>
            <Route path='/promo' component={LandingPage} />
            <Route path='/music' component={Home}/>
            <Route path='/Contact' component={Contact}/>
            <Route path='/Tour' component={Tour}/>
            <Route path='/Shop' component={Shop}/>
            <Route path='/privacy' component={Privacy}/>
            <Route path='/sitemap' component={Sitemap}/>
            <Route render={() => <Redirect to="/" />} />
        </Switch>
    );
}

export default RouteControl;
