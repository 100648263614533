import { NavLink } from "react-router-dom";
import clsx from "clsx";
import styles, {navButton, navContainer, navMobile, footerLinks} from "./side-navigation.module.scss"
import {useEffect, useState} from "react";
import Newsletter from "../newsletter/Newsletter";
import ggProduction from '../../assets/ggProduction.png'

const SideNavigation = (props) => {
    const {isMobile} = props;
    const [isOpen, setIsOpen] = useState(false);

    function handleNavChange(){
        setIsOpen(isOpen => !isOpen);
    }

    let winX, winY = null;

    function disableWindowScroll() {
        winX = window.scrollX;
        winY = window.scrollY;
    }

    function enableWindowScroll() {
        winX = null;
        winY = null;
    }

    useEffect(() => {
        const body = document.getElementsByTagName('body')[0]
        if(isMobile){
            if(isOpen){
                body.style.overflowY = 'hidden';
                body.style.pointerEvents = 'none';
                disableWindowScroll();
            }
            else {
                body.style.overflowY = 'scroll';
                body.style.pointerEvents = 'auto';
                enableWindowScroll();
            }
        }
        window.addEventListener('scroll', () => {
            if (winX !== null && winY !== null) {
                window.scrollTo(winX, winY);
            }
        });
        window.addEventListener('onscroll', () => {
            if (winX !== null && winY !== null) {
                window.scrollTo(winX, winY);
            }
        });
        return () => {
            window.removeEventListener('scroll', () => enableWindowScroll());
            window.removeEventListener('onscroll', () => enableWindowScroll());
        }
    }, [isMobile])

    return(
        <>
            {isMobile &&
                <div className={clsx(styles.menuButton, isOpen && styles.active)} id="toggle" onClick={handleNavChange}>
                    <span className={styles.top}></span>
                    <span className={styles.middle}></span>
                    <span className={styles.bottom}></span>
                </div>
            }
            <div className={clsx(isMobile && styles.overlay, isOpen && styles.open)} id="overlay">
                <nav className={clsx(isMobile ? navMobile : navContainer)}>
                    <NavLink className={navButton} to="/music" onClick={handleNavChange}>Music</NavLink>
                    <NavLink className={navButton} to="/tour" onClick={handleNavChange}>Tour</NavLink>
                    <NavLink className={navButton} to="/shop" onClick={handleNavChange}>Shop</NavLink>
                    <NavLink className={navButton} onClick={handleNavChange} to="/contact">Contact</NavLink>

                    {isMobile &&
                        <section className={footerLinks}>
                            <NavLink className={navButton} to="/privacy" onClick={handleNavChange}>Privacy</NavLink>
                            <NavLink className={navButton} to="/sitemap" onClick={handleNavChange}>Sitemap</NavLink>
                            <Newsletter />
                            <a
                                href="https://gergelygajdos.com/"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <img src={ggProduction} alt="Gajdos Gergely Production"/>
                            </a>
                        </section>
                    }
                </nav>
            </div>
        </>
    )
}

export default SideNavigation;