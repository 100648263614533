import React from "react";
import "./home.scss";

function Home() {
    return (
        <div className="videoContainer">
            <div className="video">
                <iframe 
                title="imgxxd - sounds different"
                src="https://open.spotify.com/embed/album/37YPjRx7nCiRbfSaYSht7v?utm_source=generator" 
                width="100%"
                height="380" 
                frameBorder="0" 
                allowfullscreen=""
                allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" 
                loading="lazy" />
            </div>
        </div>
    );
}

export default Home;
