import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faYoutube} from "@fortawesome/free-brands-svg-icons/faYoutube";
import {faInstagram} from "@fortawesome/free-brands-svg-icons/faInstagram";
import {faApple} from "@fortawesome/free-brands-svg-icons/faApple";
import {faSpotify} from "@fortawesome/free-brands-svg-icons/faSpotify";
import {faPlayCircle} from "@fortawesome/free-solid-svg-icons/faPlayCircle";

import {socials} from "./socials.module.scss";

const Socials = () => {
    return (
        <article className={socials}>
            <a href="https://music.youtube.com/channel/UCxVerM-Ku_JcjZpO5HKCOWQ" target="_blank" rel="noreferrer noopener">
                <FontAwesomeIcon icon={faPlayCircle} />
            </a>

            <a href="https://www.youtube.com/channel/UCv_IYHV2vYcVG1LpZONFhBw/featured" target="_blank" rel="noreferrer noopener">
                <FontAwesomeIcon icon={faYoutube} />
            </a>

            <a href="https://www.instagram.com/imgxxd420" target="_blank" rel="noreferrer noopener">
                <FontAwesomeIcon icon={faInstagram} />
            </a>

            <a href="https://open.spotify.com/artist/6xdyRkt307dtFNLweGabNj" target="_blank" rel="noreferrer noopener">
                <FontAwesomeIcon icon={faSpotify} />
            </a>

            <a href="https://music.apple.com/hu/artist/imgxxd/1610161018" target="_blank" rel="noreferrer noopener">
                <FontAwesomeIcon icon={faApple} />
            </a>

        </article>
    )
}

export default Socials;
