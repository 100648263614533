import React from "react";
import {NavLink} from "react-router-dom";

function Sitemap() {
    return (
        <nav className="sitemap">
            <NavLink to="/music">Music</NavLink>
            <NavLink to="/tour">Tour</NavLink>
            <NavLink to="/shop">Shop</NavLink>
            <NavLink to="/contact">Contact</NavLink>
            <NavLink to="/privacy">Privacy</NavLink>
            <NavLink to="/">Home</NavLink>
        </nav>
    );
}

export default Sitemap;
