import React from 'react';
import { NavLink } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import {useScreenSize} from "../../helpers/media-query";
import Socials from "../socials/Socials";
import Newsletter from "../newsletter/Newsletter";
import styles, {left, right, toLeft, toRight} from './footer.module.scss';
import ggProduction from '../../assets/ggProduction.png'
function Footer() {
    const {pathname} = useLocation();
    let {isLarge} = useScreenSize();
    const isLanding = pathname.includes("promo");
    const showMobileNav = !isLarge;
    return (
        <>
        {!isLanding &&
            <footer className={styles.footer}>
                {!showMobileNav &&
                    <aside className={left}>
                        <aside className={toLeft}>
                            <a
                                href="https://gergelygajdos.com/"
                                target="_blank"
                                rel="noreferrer noopener"
                            >
                                <img src={ggProduction} alt="Gajdos Gergely Production"/>
                            </a>
                        </aside>
                        <aside className={toRight}>
                            <NavLink to="/privacy">Privacy</NavLink>
                            <NavLink to="/sitemap">Sitemap</NavLink>
                        </aside>
                    </aside>
                }
                <Socials/>
                {!showMobileNav &&
                <aside className={right}>
                    <Newsletter/>
                </aside>
                }
            </footer>
        }
        </>
    );
}

export default Footer;