import React, {useState,useEffect} from "react";
import { Router as RouterContainer } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import Content from "./Content";
import Footer from "./components/footer/Footer";
import history from "./components/router/history";
import {ApiContext} from "./components/contexts/ApiContext";
import {callApi} from "./services/apiService";
import "bootstrap/dist/css/bootstrap.min.css";
import "./css/app.scss";

function App() {
    const [isLoading, setIsLoading] = useState(true);
    const [featuredVideo, setFeaturedVideo] = useState({});

    function getLink(){
        callApi('/featured/video')
            .then(({data}) => setFeaturedVideo({
                success: true,
                link: data.video.link
            }))
            .catch((response) => setFeaturedVideo(response.data))
            .finally(() => setIsLoading(false));
    }
    useEffect(() => {
        getLink();
    }, [])

    useEffect(() => {
        beacon(window.location.pathname);
        history.listen((location, action) => {
            // console.log(action, location.pathname, location.state);
            let path = location.pathname ?? '';
            beacon(path);
        });
    }, [history]);

    function didVisitLanding(){
        let hasSeen = window.localStorage.getItem("seenLandingPage");
        if(!hasSeen) {
            window.localStorage.setItem("seenLandingPage", 'true');
        }
        return hasSeen;
    }

    function beacon(referer) {
        callApi("/beacon", {
            'page': referer
        }, "get")
            .then(response => {
            })
            .catch( (error) => {
                // console.error(error);
                // responseHandler("error", error)
            });
    }

    // TODO - SCSS Files will need to be tidied up
    return isLoading ? (
        <div className="loading"></div>
    ) : (
        <ApiContext.Provider value={{
            featured_video: featuredVideo,
            didVisitLanding
        }}>
            <RouterContainer history={history}>
                <ToastContainer />
                <article className="fogContainer">
                    <article className="fogWrapper">
                        <div className="fogImg img1"></div>
                        {/*<div className="fogImg img2"></div>*/}
                    </article>
                </article>
                <section>
                    <Content />
                    <Footer />
                </section>
            </RouterContainer>
        </ApiContext.Provider>
    );
}

export default App;
