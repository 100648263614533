import React from "react";
import clsx from "clsx";
import {useLocation} from "react-router-dom";
import Router from "./components/router/Route";
import SideNavigation from "./components/side-navigation/SideNavigation";
import portrait from "./assets/landingNew.png";
import styles from "./pages/home/home.module.scss";
import {useScreenSize} from "./helpers/media-query";

const Content = () => {
    let {isXSmall, isSmall, isLarge, isMedium} = useScreenSize();
    const {pathname} = useLocation();
    const contact = pathname.includes('contact');
    const hideImg = pathname.includes('sitemap') || pathname.includes('privacy') || pathname.includes('tour') || pathname.includes('shop') || pathname.includes('contact');
    const isLanding = pathname.includes('promo');
    const showMobileNav = !isLarge;
    // TODO - Create context that will contain tour dates, contact details etc
    // TODO - Loading gif for yt embed
    // TODO - Load yt link from API

    function shouldImageShow(){
        if(isXSmall || isSmall){
            if(hideImg) return;
        }
        return <img src={portrait} alt="imgxxd"/>;
    }

    return (
        <main>
            <section className={clsx((isMedium && styles.landingPage), "landingPage")}>
                {/*{!isMedium*/}
                {/*    ? <img src={portrait}/>*/}
                {/*    : <section className={styles.portrait}></section>*/}
                {/*}*/}
                {/*{(!hideImg && (!isXSmall || !isSmall)) && <img src={portrait}/>}*/}
                {shouldImageShow()}
                <section className={clsx(styles.latest, contact && 'contactScreen' )}>
                    <Router />
                </section>
            </section>
            {!isLanding && <SideNavigation isMobile={showMobileNav} />}
        </main>
    )
}

export default Content;