import React from "react";

function Privacy() {
    return (
        <section className="privacy">
            <p>By subscribing to our newsletter, you agree to receive marketing emails. You may withdraw your consent to receiving the newsletter at any time by clicking the link in the email. Unsubscribing may take up to 24 hours to take effect.</p>
            <p>We do not use cookies, or similar tracking technologies for tracking and do not store any personal information.</p>
        </section>
    );
}

export default Privacy;
